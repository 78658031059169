exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blog-attractions-index-js": () => import("./../../../src/pages/blog/attractions/index.js" /* webpackChunkName: "component---src-pages-blog-attractions-index-js" */),
  "component---src-pages-blog-carrental-index-js": () => import("./../../../src/pages/blog/carrental/index.js" /* webpackChunkName: "component---src-pages-blog-carrental-index-js" */),
  "component---src-pages-blog-flights-index-js": () => import("./../../../src/pages/blog/flights/index.js" /* webpackChunkName: "component---src-pages-blog-flights-index-js" */),
  "component---src-pages-blog-hotel-index-js": () => import("./../../../src/pages/blog/hotel/index.js" /* webpackChunkName: "component---src-pages-blog-hotel-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-places-index-js": () => import("./../../../src/pages/blog/places/index.js" /* webpackChunkName: "component---src-pages-blog-places-index-js" */),
  "component---src-pages-blog-property-index-js": () => import("./../../../src/pages/blog/property/index.js" /* webpackChunkName: "component---src-pages-blog-property-index-js" */),
  "component---src-pages-blog-restaurants-index-js": () => import("./../../../src/pages/blog/restaurants/index.js" /* webpackChunkName: "component---src-pages-blog-restaurants-index-js" */),
  "component---src-pages-blog-tours-index-js": () => import("./../../../src/pages/blog/tours/index.js" /* webpackChunkName: "component---src-pages-blog-tours-index-js" */),
  "component---src-pages-blog-transfer-index-js": () => import("./../../../src/pages/blog/transfer/index.js" /* webpackChunkName: "component---src-pages-blog-transfer-index-js" */),
  "component---src-pages-carrental-index-js": () => import("./../../../src/pages/carrental/index.js" /* webpackChunkName: "component---src-pages-carrental-index-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thankyou-js": () => import("./../../../src/pages/contact/thankyou.js" /* webpackChunkName: "component---src-pages-contact-thankyou-js" */),
  "component---src-pages-hotels-index-js": () => import("./../../../src/pages/hotels/index.js" /* webpackChunkName: "component---src-pages-hotels-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-cancellation-js": () => import("./../../../src/pages/information/cancellation.js" /* webpackChunkName: "component---src-pages-information-cancellation-js" */),
  "component---src-pages-information-privacy-js": () => import("./../../../src/pages/information/privacy.js" /* webpackChunkName: "component---src-pages-information-privacy-js" */),
  "component---src-pages-information-termsconditions-js": () => import("./../../../src/pages/information/termsconditions.js" /* webpackChunkName: "component---src-pages-information-termsconditions-js" */),
  "component---src-pages-invoice-tour-js": () => import("./../../../src/pages/invoice/tour.js" /* webpackChunkName: "component---src-pages-invoice-tour-js" */),
  "component---src-pages-payment-create-js": () => import("./../../../src/pages/payment/create.js" /* webpackChunkName: "component---src-pages-payment-create-js" */),
  "component---src-pages-payment-custom-js": () => import("./../../../src/pages/payment/custom.js" /* webpackChunkName: "component---src-pages-payment-custom-js" */),
  "component---src-pages-payment-index-js": () => import("./../../../src/pages/payment/index.js" /* webpackChunkName: "component---src-pages-payment-index-js" */),
  "component---src-pages-payment-thankyou-js": () => import("./../../../src/pages/payment/thankyou.js" /* webpackChunkName: "component---src-pages-payment-thankyou-js" */),
  "component---src-pages-properties-index-js": () => import("./../../../src/pages/properties/index.js" /* webpackChunkName: "component---src-pages-properties-index-js" */),
  "component---src-pages-recommendations-attractions-index-js": () => import("./../../../src/pages/recommendations/attractions/index.js" /* webpackChunkName: "component---src-pages-recommendations-attractions-index-js" */),
  "component---src-pages-recommendations-places-index-js": () => import("./../../../src/pages/recommendations/places/index.js" /* webpackChunkName: "component---src-pages-recommendations-places-index-js" */),
  "component---src-pages-recommendations-restaurants-index-js": () => import("./../../../src/pages/recommendations/restaurants/index.js" /* webpackChunkName: "component---src-pages-recommendations-restaurants-index-js" */),
  "component---src-pages-tours-index-js": () => import("./../../../src/pages/tours/index.js" /* webpackChunkName: "component---src-pages-tours-index-js" */),
  "component---src-pages-transfers-dominican-republic-index-js": () => import("./../../../src/pages/transfers/dominican-republic/index.js" /* webpackChunkName: "component---src-pages-transfers-dominican-republic-index-js" */),
  "component---src-pages-transfers-flights-index-js": () => import("./../../../src/pages/transfers/flights/index.js" /* webpackChunkName: "component---src-pages-transfers-flights-index-js" */),
  "component---src-pages-transfers-index-js": () => import("./../../../src/pages/transfers/index.js" /* webpackChunkName: "component---src-pages-transfers-index-js" */),
  "component---src-pages-transfers-punta-cana-index-js": () => import("./../../../src/pages/transfers/punta-cana/index.js" /* webpackChunkName: "component---src-pages-transfers-punta-cana-index-js" */),
  "component---src-pages-travelagent-[id]-js": () => import("./../../../src/pages/travelagent/[id].js" /* webpackChunkName: "component---src-pages-travelagent-[id]-js" */),
  "component---src-pages-travelagent-agentbooking-[id]-js": () => import("./../../../src/pages/travelagent/agentbooking/[id].js" /* webpackChunkName: "component---src-pages-travelagent-agentbooking-[id]-js" */),
  "component---src-pages-travelagent-agentbooking-index-js": () => import("./../../../src/pages/travelagent/agentbooking/index.js" /* webpackChunkName: "component---src-pages-travelagent-agentbooking-index-js" */),
  "component---src-pages-travelagent-cart-index-js": () => import("./../../../src/pages/travelagent/cart/index.js" /* webpackChunkName: "component---src-pages-travelagent-cart-index-js" */),
  "component---src-pages-travelagent-contact-index-js": () => import("./../../../src/pages/travelagent/contact/index.js" /* webpackChunkName: "component---src-pages-travelagent-contact-index-js" */),
  "component---src-pages-travelagent-contact-thankyou-js": () => import("./../../../src/pages/travelagent/contact/thankyou.js" /* webpackChunkName: "component---src-pages-travelagent-contact-thankyou-js" */),
  "component---src-pages-travelagent-forgotpassword-js": () => import("./../../../src/pages/travelagent/forgotpassword.js" /* webpackChunkName: "component---src-pages-travelagent-forgotpassword-js" */),
  "component---src-pages-travelagent-index-js": () => import("./../../../src/pages/travelagent/index.js" /* webpackChunkName: "component---src-pages-travelagent-index-js" */),
  "component---src-pages-travelagent-reserved-[id]-js": () => import("./../../../src/pages/travelagent/reserved/[id].js" /* webpackChunkName: "component---src-pages-travelagent-reserved-[id]-js" */),
  "component---src-pages-travelagent-reserved-index-js": () => import("./../../../src/pages/travelagent/reserved/index.js" /* webpackChunkName: "component---src-pages-travelagent-reserved-index-js" */),
  "component---src-pages-travelagent-signin-js": () => import("./../../../src/pages/travelagent/signin.js" /* webpackChunkName: "component---src-pages-travelagent-signin-js" */),
  "component---src-pages-travelagent-signup-js": () => import("./../../../src/pages/travelagent/signup.js" /* webpackChunkName: "component---src-pages-travelagent-signup-js" */),
  "component---src-pages-travelagent-touroperators-[id]-js": () => import("./../../../src/pages/travelagent/touroperators/[id].js" /* webpackChunkName: "component---src-pages-travelagent-touroperators-[id]-js" */),
  "component---src-pages-travelagent-touroperators-index-js": () => import("./../../../src/pages/travelagent/touroperators/index.js" /* webpackChunkName: "component---src-pages-travelagent-touroperators-index-js" */),
  "component---src-pages-travelagent-tours-index-js": () => import("./../../../src/pages/travelagent/tours/index.js" /* webpackChunkName: "component---src-pages-travelagent-tours-index-js" */),
  "component---src-pages-travelagent-transfer-[id]-js": () => import("./../../../src/pages/travelagent/transfer/[id].js" /* webpackChunkName: "component---src-pages-travelagent-transfer-[id]-js" */),
  "component---src-pages-travelagent-transfer-index-js": () => import("./../../../src/pages/travelagent/transfer/index.js" /* webpackChunkName: "component---src-pages-travelagent-transfer-index-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-hotel-js": () => import("./../../../src/templates/hotel.js" /* webpackChunkName: "component---src-templates-hotel-js" */),
  "component---src-templates-property-js": () => import("./../../../src/templates/property.js" /* webpackChunkName: "component---src-templates-property-js" */),
  "component---src-templates-reviews-js": () => import("./../../../src/templates/reviews.js" /* webpackChunkName: "component---src-templates-reviews-js" */),
  "component---src-templates-tour-js": () => import("./../../../src/templates/tour.js" /* webpackChunkName: "component---src-templates-tour-js" */),
  "component---src-templates-travel-agent-js": () => import("./../../../src/templates/travelAgent.js" /* webpackChunkName: "component---src-templates-travel-agent-js" */)
}

